import React from 'react';

const ListTodo = ({ todos, deleteTodo }) => {
  return (
    <ul>
      {todos && todos.length > 0 ? (
        todos.map((todo) => {
          return (
            <li key={todo._id} onClick={() => deleteTodo(todo._id)}>
              {todo.action} 
              { (todo.author) && 
                <> ({todo.author?.name}</>
              }
              { (todo.author?.country) && 
                <> 
                  , {todo.author.country}
                  ) 
              </>
            }
            </li>
          );
        })
      ) : (
        <li>No todo(s) left</li>
      )}
    </ul>
  );
};

export default ListTodo;